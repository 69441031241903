<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl fluid>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-card>
                        <v-card-title>
                                <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij iskanja</span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('user-data')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <!--<v-layout row wrap>
                                <v-flex xs12 sm12 md4 lg3>
                                    <v-btn class="mt-3" @click="clickToScan" v-show="!displayScanNrInput" block color="warning" dark>Klikni tukaj za skeniranje</v-btn>
                                    <v-form ref="form"
                                    v-model="form"
                                    append>
                                        <v-text-field
                                            v-model="scan_nr"
                                            label="Prisloni kartico na optični čitalnik...."
                                            v-show="displayScanNrInput"
                                            ref="scan_input"
                                            @keydown.enter.prevent="scanCard"
                                            id="scanInputField"
                                            class="ml-4 cb"
                                        ></v-text-field>
                                        
                                    </v-form>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 id="abc">
                                    <v-checkbox @change="displayResults" class="ti" v-model="blocked_users" :label="`Prikaži samo blokirane kartice: ${checkboxBlockedCardsOnlyLabel}`"></v-checkbox>
                                </v-flex>
                            </v-layout>-->
                            <!-- tipi omejitev -->
                            <v-container grid-list-xl text-x-center align-center>
                                <v-layout row justify-center v-bind="columnBinding">
                                    <v-flex xs12 md4 align-content-center>
                                        <v-subheader class="pt-7 pl-0"><strong>Tip omejitve</strong></v-subheader>
                                    </v-flex>
                                    <v-flex xs12 md8 align-content-center>
                                        <v-select
                                        v-model="selected_restriction_types"
                                        :items="restrictionTypes"
                                        label="Izberi tip omejitve"
                                        multiple
                                        chips
                                        clearable
                                        deletable-chips
                                        prepend-icon="mdi-monitor-star"
                                        item-value="id"
                                        item-text="name"
                                        return-object
                                        no-data-text="Ni podatkov za prikaz"
                                    ></v-select>   
                                    </v-flex>
                                </v-layout>
                            </v-container>

                            <!-- telefonska številka -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Telefonska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="phone_number"
                                            label="Vpiši telefonsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearPhoneNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- registrska številka -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Registrska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="registration_number"
                                            label="Vpiši registrsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearRegistrationNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>
                        <v-divider v-if="card1">
                        </v-divider>
                        <span v-if="card1">
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                            <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                            </v-card-text>
                            <v-card-text v-else>
                                <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                            </v-card-text>
                        </span>
                    </v-card>
                </v-flex>
            </v-layout>
            <br>
            <users-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></users-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
const UsersDataTable = () => import('@/components/UsersDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
import { isMobileDevice } from '@/helpers/utilities.js'

export default {
    components: {
        UsersDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        card1: true,
        card2: true,

        blocked_users: false,
        scan_nr: null,
        scan_btn: true,
        form: true,

        registration_number: null,
        phone_number: null,

        selected_restriction_types: [],
        restrictionTypes: [],

        dataTableSettings: {
            id: 'dtEvents',
            title: 'Seznam uporabnikov',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true, width: "5%"},
                { text: "Ime in priimek", value: "name", class: "text-xs-left", visibility: true, width: "20%"},
                { text: "Email", value: "email", class: "text-xs-left", visibility: true},
                { text: "Tip omejitve", value: "restriction", class: "text-xs-left", visibility: true },
                //{ text: "ID kartice", value: "card_id", class: "text-xs-left", visibility: true },
                //{ text: "Št. kartice", value: "card_number", class: "text-xs-left", visibility: true },
                //{ text: "Tel. št. kvota", value: "phone_numbers_limit", class: "text-xs-left", visibility: true, sortable: true},
                { text: "Telefonske številke", value: "phone_numbers", class: "text-xs-left", visibility: true, sortable: false, width:"24%" },
                { text: "Registrske številke", value: "registration_numbers", class: "text-xs-left", visibility: true, sortable: false, width:"24%" },
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'v1/admin/users',
            editItem: {
                route: 'admin_edit_user',
                routeParameterValue: 'id'
            },
            deleteItem: {
                vuexAction: 'DELETE_RFID_USER',
                replaceStrWithColumnValue: 'name',
                confirmationText : "Sta prepričani da želite odstraniti uporabnika {str_to_replace} ?",
                dialogConfirmSuccessText: 'Uporabnik {str_to_replace} je bil uspešno odstranjen.',
                dialogConfirmFailText: 'Pri odstranjevanju uporabnika {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: true,
                    addNewRoute: 'admin_add_user',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_rfid_users',
                totalItems : {
                    event: ''
                },
                search: 'dt-rfid-users-search',
                toggleColumnsId: 'toggle-rfid-users-list-columns'
            }


        }
    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                blockedUsers: this.blocked_users,
                registrationNumber: this.registration_number,
                phoneNumber: this.phone_number,
                selectedRestrictionTypes: this.selected_restriction_types.map(item => item.id),

            }
        },

        checkboxBlockedCardsOnlyLabel() {
            if(this.blocked_users) {
                return "DA"
            }

            return "NE"
        },

        displayScanNrInput() {
            if(!this.scan_btn) {
                return true
            }

            return false
        },
    },

    watch: {

    },

    methods: {
        toggleCardContent(card) {
            if(card === 'user-data') {
                this.card1 = !this.card1
            }
        },

        search() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        },

        clickToScan() {
            this.scan_btn = false
            this.setFocus()
            var vm = this
        },

        setFocus() {
            if(!this.scan_btn) {
                var vm = this
                setTimeout(() => {
                    vm.$refs.scan_input.focus()
                }, 250)
            }
        },

        scanCard() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('RFID_NR_SEARCH', this.scan_nr)
            .then(response => {
                //console.log(response)
                //console.log(response.data.rfidNr)
                EventBus.$emit(this.dataTableSettings.eventBus.search, response.data.rfidNr.toString());
                this.scan_nr = null
                this.scan_btn = true
                vm.$store.commit('SET_PROGRESS', false)
            })
            .catch(error => {
                this.scan_nr = null
                this.scan_btn = true
                vm.$store.commit('SET_PROGRESS', false)
                vm.$store.commit('snackbarDisplay', true)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', 'Pri skeniranju kartice je prišlo do napake. Prosimo poskusite ponovno.')
                vm.$store.commit('snackbarDisplay', true)
            })
        },

        clearPhoneNumber() {
            this.phone_number = null
        },

        clearRegistrationNumber() {
            this.registration_number = null
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
        if(isMobileDevice(this)) {
            this.card1 = false
        }
    },

    mounted() {
        var vm = this
        /*document.getElementById("scanInputField").addEventListener("blur", function(){
            vm.scan_btn = true
        });*/

        vm.$store.commit('SET_PROGRESS', true)
        vm.$store.dispatch('ADMIN_RESOURCES')
        .then(response => {
            //window.console.log(response.data)

            response.data.restriction_types.forEach(restrictionType => {
                vm.restrictionTypes.push(restrictionType)
            })
        })
        .catch(error => {
            window.console.error("### ERROR Users.vue@mounted ###");
            window.console.error(error)
        })
        .finally(() => {
            vm.$store.commit('SET_PROGRESS', false)
        })
    },

    destroyed() {
        /*EventBus.$off(this.dataTableSettings.id)*/
    }
}

</script>

<style scoped>

</style>